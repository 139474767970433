import { validatePhone, hideShowPass } from './utils';
import '../../css/Login/style.css';
import '../../css/style.css';

const loginContainer = document.querySelector('.login__container');

const checkBlockLogin = (e) => {
  const { target } = e;
  const loginCommon = document.querySelector('.login__common');
  const titleReg = document.querySelector('.registration-title');
  const titleAut = document.querySelector('.autorization-title');
  if (target.classList.contains('form__help')) {
    loginContainer.innerHTML = null;
    loginContainer.innerHTML = `${require('../../components/Login/form-recovery-password.html')}`;
  }
  if (target.classList.contains('login__return')) {
    loginContainer.innerHTML = null;
    loginContainer.innerHTML = `${require('../../components/Login/login-aut-and-reg.html')}`;
    loginContainer.addEventListener('click', checkBlockLogin, true);
  }
  if (target.classList.contains('autorization-title')) {
    target.classList.remove('title-notactive');
    titleReg.classList.add('title-notactive');
    loginCommon.innerHTML = null;
    loginCommon.innerHTML = `${require('../../components/Login/form-authorization.html')}`;
  }

  if (target.classList.contains('registration-title')) {
    target.classList.remove('title-notactive');
    titleAut.classList.add('title-notactive');
    loginCommon.innerHTML = null;
    loginCommon.innerHTML = `${require('../../components/Login/form-registration.html')}`;
    const btnPass = document.querySelector('.btn-change');
    const pass = document.querySelector('#password');
    const eye = document.querySelector('.eye');
    hideShowPass(btnPass, eye, pass);
    validatePhone('#phone');
  }
};

loginContainer.addEventListener('click', checkBlockLogin, true);
