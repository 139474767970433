import intlTelInput from 'intl-tel-input';
import telUtils from 'intl-tel-input/build/js/utils';
import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';

export const addTrim = value => value.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

export const validatePhone = (cls) => {
  const input = document.querySelector(cls);

  const iti = intlTelInput(input, {
    preferredCountries: ['ru', 'by', 'kz', 'ua'],
    formatOnDisplay: true,
    utlisScript: telUtils,
    geoIpLookup: 'auto',
    placeholderNumberType: 'MOBILE',
  });

  // Проверка правильности набора номера:
  const errorMsg = document.querySelector('.error-msg');
  const validMsg = document.querySelector('.valid-msg');

  if (input) {
    const reset = () => {
      input.classList.remove('error');
      errorMsg.innerHTML = '';
      errorMsg.classList.add('hide');
      validMsg.classList.add('hide');
    };

    // Validate on blur event
    input.addEventListener('blur', () => {
      reset();
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          validMsg.classList.remove('hide');
        } else {
          input.classList.add('error');
          errorMsg.innerHTML = '&#10006;';
          errorMsg.classList.remove('hide');
        }
      }
    });

    // Reset on keyup/change event
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
};

// пользовательское соглашение
export const checkedTerms = (el, cls) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const { target } = e;
    const spanChecked = document.querySelector('.control_indicator');
    const topupBtn = document.querySelector(cls);
    if (target.classList.contains('control-checkbox') || target.classList.contains('control_indicator')) {
      if (spanChecked.classList.contains('checked')) {
        spanChecked.classList.remove('checked');
        topupBtn.disabled = 'true';
        topupBtn.classList.add('disabled-button');
      } else {
        topupBtn.removeAttribute('disabled');
        spanChecked.classList.add('checked');
        topupBtn.classList.remove('disabled-button');
      }
    }
  });
};

// показать скрыть пароль
export const hideShowPass = (elhandler, el, pass) => {
  elhandler.addEventListener('click', () => {
    if (pass.type === 'text') {
      const password = pass;
      password.type = 'password';
      el.classList.add('fa-eye');
      el.classList.remove('fa-eye-close');
    } else {
      const password2 = pass;
      password2.type = 'text';
      el.classList.remove('fa-eye');
      el.classList.add('fa-eye-close');
    }
  });
};
export const getNumberCount = (el) => {
  const filesCountEl = el.querySelector('.files-count');
  const countFilesNumber = el.querySelectorAll('.files-file').length;
  filesCountEl.innerText = `(${countFilesNumber})`;
};

export const removeNameFile = (str) => {
  const index = str.indexOf('.') + 1;
  return str.substring(index);
};


export const loadFiles = (el, fileSelectorCls, fileLoadCls, childrenCount) => {
  let state = {};
  const fileSelector = el.querySelector(fileSelectorCls);
  const fileLoad = el.querySelector(fileLoadCls);
  const filesCountEl = el.querySelector('.files-count');
  const popUpBlock = document.querySelector('.form__popup');
  function renderFileList(elems) {
    const fileMap = state.filesArr.map((file) => {
      let suffix = 'bytes';
      let { size } = file;
      if (size >= 1024 && size < 1024000) {
        suffix = 'KB';
        size = Math.round(size / 1024 * 100) / 100;
      } else if (size >= 1024000) {
        suffix = 'MB';
        size = Math.round(size / 1024000 * 100) / 100;
      }
      return `<li class="files-file"><a href="#" download><span>${childrenCount === 0 ? removeNameFile(file.name) : file.name}</span> </a><span class="file-size">${size} ${suffix}</span></li>`;
    });
    elems.insertAdjacentHTML('afterBegin', fileMap.join(',').replace(/,/g, '').split());
    if (filesCountEl) {
      setTimeout(getNumberCount(el), 100);
    }
    if (popUpBlock) {
      if (document.querySelectorAll('.files-file').length >= 1) {
        setTimeout(() => {
          document.querySelector('.popup-upload').classList.add('upload__file');
          document.querySelector('.popup-button').classList.remove('button-noactive');
          document.querySelector('.input__text').innerText = null;
        }, 100);
      }
    }
  }

  function updateState(newState) {
    state = { ...state, ...newState };
  }
  if (fileSelector) {
    fileSelector.addEventListener('change', (e) => {
      const { target } = e;
      // const { files } = fileSelector;
      const ulList = fileLoad.children[childrenCount];
      const fileList = target.files;
      const filesArr = Array.from(fileList);
      updateState({ filesArr });
      renderFileList(ulList);
    });
  }
};

// функция для добавления файлов через input
export function addFiles(cls) {
  const inputs = document.querySelectorAll(cls);
  inputs.forEach((input) => {
    const label = input.nextElementSibling;
    const labelVal = label.innerHTML;

    input.addEventListener('change', (e) => {
      const { target } = e;
      let fileName = '';
      if (target.files && e.target.files.length > 1) fileName = (e.target.getAttribute('data-multiple-caption') || '').replace('{count}', target.files.length);
      else fileName = target.value.split('\\').pop();
      if (fileName) document.querySelector('.input__text').innerHTML = fileName;
      else label.innerHTML = labelVal;
    });

    // Firefox bug fix
    input.addEventListener('focus', () => { input.classList.add('has-focus'); });
    input.addEventListener('blur', () => { input.classList.remove('has-focus'); });
  });
}
